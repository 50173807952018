exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-archives-jsx": () => import("./../../../src/pages/Archives.jsx" /* webpackChunkName: "component---src-pages-archives-jsx" */),
  "component---src-pages-contactpage-jsx": () => import("./../../../src/pages/contactpage.jsx" /* webpackChunkName: "component---src-pages-contactpage-jsx" */),
  "component---src-pages-homework-jsx": () => import("./../../../src/pages/homework.jsx" /* webpackChunkName: "component---src-pages-homework-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-templates-homework-post-js": () => import("./../../../src/templates/homework-post.js" /* webpackChunkName: "component---src-templates-homework-post-js" */)
}

